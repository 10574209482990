import classnames from 'classnames';
import React, { CSSProperties, useCallback, useEffect } from 'react';
import { BaseComponent } from 'src/app/interfaces/BaseComponent';
import { isMobile } from 'src/app/utils/utils';
import { Body } from 'src/view/components/Body/Body';
import CloseButton from 'src/view/components/CloseButton';
import { Heading } from 'src/view/components/Heading/Heading';
import $ from 'src/view/components/Modal/Modal.module.scss';

export interface ModalChildrenProps {
    children: React.ReactNode;
    dataCy?: string;
}

export const ModalTitle = ({ children, dataCy }: ModalChildrenProps) => {
    return (
        <Heading dataCy={dataCy} variant="h2">
            {children}
        </Heading>
    );
};

export const ModalBody = ({ children, dataCy }: ModalChildrenProps) => {
    return (
        <div data-cy={dataCy} className={$.modalBody}>
            {children}
        </div>
    );
};

interface ModalFooterProps extends ModalChildrenProps {
    justifyContent?: CSSProperties['justifyContent'];
}

export const ModalFooter = ({ children, justifyContent = 'center', dataCy }: ModalFooterProps) => {
    return (
        <div data-cy={dataCy} className={$.modalFooter} style={{ justifyContent }}>
            {children}
        </div>
    );
};

export enum MODAL_WIDTH {
    SMALL = 'SMALL',
}

export interface ModalProps extends BaseComponent {
    smallModal?: boolean;
    title?: string | JSX.Element;
    subTitle?: string | JSX.Element;
    onClose: () => void;
    open: boolean;
    width?: CSSProperties['width'];
    modalDialogClassName?: string;
    size?: MODAL_WIDTH;
}

export const Modal = ({
    onClose,
    children,
    open = false,
    width,
    title,
    subTitle,
    smallModal,
    className,
    modalDialogClassName,
    size,
}: ModalProps) => {
    const escHandler = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') onClose();
        },
        [onClose]
    );

    useEffect(() => {
        if (isMobile()) return;

        document.addEventListener('keydown', escHandler, false);

        return () => {
            document.removeEventListener('keydown', escHandler, false);
        };
    }, [escHandler]);

    useEffect(() => {
        if (open) {
            document.body.classList.add('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [open]);

    return (
        <>
            {open && (
                <div
                    className={classnames(
                        $.modalContainer,
                        smallModal && $.modalContainerSmallDialog,
                        className
                    )}
                >
                    <div className={$.modalBackdrop} onClick={onClose} />
                    <div
                        className={classnames(
                            $.modalDialog,
                            smallModal && $.modalDialogSmall,
                            modalDialogClassName,
                            size === MODAL_WIDTH.SMALL && $.modalDialogSmallSize
                        )}
                        style={{ width }}
                    >
                        <div className={$.modalCloseButton}>
                            <CloseButton onClick={onClose} />
                        </div>
                        {title && <ModalTitle>{title}</ModalTitle>}
                        {subTitle && (
                            <Body grey marginTop={false}>
                                {subTitle}
                            </Body>
                        )}
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};
