import { Alert, Grid } from '@mui/material';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RoomLayoutModel from 'src/app/models/RoomLayoutFormModel';
import { RootState } from 'src/app/store';
import { selectEventDetail } from 'src/app/store/eventSlice';
import { OrderTypeUtil, generateId } from 'src/app/utils/utils';
import { PackageType } from 'src/data/models/Order';
import { Preferences } from 'src/data/models/Preferences';
import { Traveler } from 'src/data/models/Traveler';
import * as Cache from 'src/data/services/cache';
import { getMicroCopyVariant } from 'src/view/ABComponents/TCO504MicroCopyText/TCO504MicroCopyText';
import ConfirmButton from 'src/view/components/ConfirmButton/ConfirmButton';
import { VSpacer } from 'src/view/components/Page';
import $ from './OrderPreferences.module.scss';
import { TravelerControls } from './traveler-controls';
import { TravelerWithHotelControls } from './traveler-with-hotel-controls';
import { useFixedQuantityIncrements } from './use-fixed-quantity-increments';

interface OrderPreferencesTravelerControlsProps {
    packageType: PackageType;
    errors: string[]; // Is a state from Ticket.tsx
    isSubmitting: boolean; // Is a state from Ticket.tsx
    autoConfirmPreferences: boolean;
    priceChanged: boolean; // LOOKS LIKE DEPCRECATED AND ALWAYS FALSE
    preferencesConfirmed: boolean; // Is a state from Ticket.tsx
    onConfirmPreferences: (preferences: Preferences) => void;
    onChange: (preferences: Preferences) => void;
    onRefreshOrderWithPreferencesUpdate: (shouldRefresh: boolean, preferences: Preferences) => void;
}

// TODO: Improve the naming convention of the component
export const OrderPreferencesTravelerControls = ({
    autoConfirmPreferences,
    onConfirmPreferences,
    onRefreshOrderWithPreferencesUpdate,
    priceChanged,
    onChange,
    packageType,
    preferencesConfirmed,
    isSubmitting,
    errors,
}: OrderPreferencesTravelerControlsProps) => {
    const { t } = useTranslation();
    const eventDetail = useSelector(selectEventDetail);
    const cachedPreferences = Cache.getPreferences();

    const { hasFixedStepIncrement, incrementalStepOptions, isEveryIncrementLargerThanTen } =
        useFixedQuantityIncrements();

    /** IMPORTANT: This state holds information only for TICKET_ONLY
     * Doesn't hold the adults information for HOTEL_TICKET
     */
    const [adults, setAdults] = useState<Traveler[]>(
        cachedPreferences?.adults || [
            new Traveler({ type: 'adult' }),
            new Traveler({ type: 'adult' }),
        ]
    );
    /** END TICKET_ONLY STATE  */

    /** IMPORTANT: HOTEL_TICKET STATE  */
    /** Check-in and check-out dates for hotels */
    const [checkInDate, setCheckInDate] = useState<number>(cachedPreferences?.inDate || 0); // date_end
    const [checkOutDate, setCheckOutDate] = useState<number>(cachedPreferences?.outDate || 0); // date_start
    const defaultRoomLayout =
        cachedPreferences?.roomLayout && cachedPreferences.roomLayout.length > 0
            ? cachedPreferences.roomLayout
            : [
                  {
                      id: generateId(),
                      adults: incrementalStepOptions.includes(2) ? 2 : incrementalStepOptions[0],
                      children: 0,
                      childInformation: [],
                  },
              ];
    /** Holds the information of how many rooms, adults&children per room */
    const [roomLayout, setRoomLayout] = useState<RoomLayoutModel[]>(defaultRoomLayout);
    /** END HOTEL_TICKET STATE  */

    /** IMPORTANT: Preferences object which holds the values which will be sent to the backend */
    const currentPreferences = useMemo<Preferences>(() => {
        return {
            adults,
            roomLayout: roomLayout,
            inDate: checkInDate,
            outDate: checkOutDate,
        };
    }, [checkInDate, checkOutDate, adults, roomLayout]);

    // Currently only relates to 'price changed' information, but seems like is not used anymore
    const [infoNotification, setInfoNotification] = useState<string[]>([]);
    // This seems like is not used, and will never trigger.
    // priceChanged comes from Ticket.tsx and is always hardcoded to false
    useEffect(() => {
        if (priceChanged) {
            setInfoNotification([t('ticketPriceSelectedTicketChanged')]);
        }
    }, [priceChanged]);

    // I don't think is used anymore.
    // TODO: Double confirm if this is not used anymore
    const microCopyVariant = useSelector((state: RootState) => getMicroCopyVariant(state));

    // Because the incrementalStepOptions happens async, we need to set the default value after the options are set
    useEffect(() => {
        const incrementStepsIncludeCachedPreference = incrementalStepOptions.includes(
            cachedPreferences.adults.length
        );

        /**
         * If incremental step options allow for the cached preferences, then use them.
         * If Cached preferences do exist use them instead.
         * Such logic is needed in order to prevent the incremental steps logic to override the confirmed preferences
         */
        const shouldUpdateTheDefaultAdults =
            incrementStepsIncludeCachedPreference &&
            cachedPreferences.adults.length === adults.length;

        if (shouldUpdateTheDefaultAdults) return; // will default to the 2 adults

        const adultsArray = createAdults(
            incrementalStepOptions.includes(2) ? 2 : incrementalStepOptions[0]
        );
        setAdults(adultsArray);
        Cache.setAdults(adults);
    }, [incrementalStepOptions]);

    useEffect(
        () => {
            if (!eventDetail) return;

            if (autoConfirmPreferences) return onConfirmPreferences(currentPreferences);
            onRefreshOrderWithPreferencesUpdate(false, currentPreferences);
        },
        /** We need to have a correct dependency array, but due to prop drilling, it has to be done with caution */
        [eventDetail]
    );

    // Trigger onChange when the preferences change
    useEffect(() => {
        onChange(currentPreferences);
    }, [currentPreferences]);

    const confirmButtonText = preferencesConfirmed
        ? t('ticketPreferencesConfirmed')
        : microCopyVariant
          ? t('microCopyGeneralConfirmButtonText')
          : t('ticketConfirmPreferences');

    if (isEveryIncrementLargerThanTen) {
        return <OnlyAvailableInIncrementsInfo />;
    }

    return (
        <>
            <Grid container spacing={2} className={classnames(isSubmitting && $.submitting)}>
                {hasFixedStepIncrement && (
                    <Grid item xs={12} lg={12} data-cy="ticket-check-in-selector">
                        <Alert severity="info">
                            {t(
                                'eventHasIncrementalStepWarningMessage',
                                'This event allows only specific number tickets to be sold at once.'
                            )}
                        </Alert>
                    </Grid>
                )}

                {OrderTypeUtil.onlyHasTicket(packageType) && (
                    <TravelerControls
                        incrementalStepOptions={incrementalStepOptions}
                        value={adults.length}
                        onChange={setAdults}
                    />
                )}

                {OrderTypeUtil.hasHotel(packageType) && (
                    <TravelerWithHotelControls
                        hasFixedStepIncrement={hasFixedStepIncrement}
                        incrementalStepOptions={incrementalStepOptions}
                        handleRoomChange={setRoomLayout}
                        setCheckInDate={setCheckInDate}
                        setCheckOutDate={setCheckOutDate}
                        checkInDate={checkInDate}
                        checkOutDate={checkOutDate}
                        roomLayout={roomLayout}
                    />
                )}
            </Grid>

            <VSpacer />

            <ConfirmButton
                buttonProps={{
                    text: confirmButtonText,
                    onClick: () => onConfirmPreferences(currentPreferences),
                    disabled: preferencesConfirmed,
                    isLoading: isSubmitting,
                    dataCy: 'ticket-confirm-selection',
                }}
                infoText={t('ticketConfirmPreferencesExplanation')}
                infoNotifications={infoNotification}
                errors={errors}
                microCopyText={[
                    t('orderPreferencesMicroCopyConfirm'),
                    t('orderPreferencesMicroCopyHappyFans'),
                ]}
            />
        </>
    );
};

const OnlyAvailableInIncrementsInfo = () => {
    const { t } = useTranslation();
    return (
        <Grid container>
            <Alert severity="info">
                {t(
                    'eventOnlyAvailableForMoreThanTenPeople',
                    'Please be aware that this booking is specifically for groups exceeding 10 people and cannot be completed through our website. To secure your reservation, kindly get in touch with us via +442045255846 or info@p1travel.com.'
                )}
            </Alert>
        </Grid>
    );
};

export function createAdults(count: number) {
    const adults: Traveler[] = [];

    for (let i = 0; i < count; i += 1) {
        adults.push(new Traveler({ type: 'traveller' }));
    }

    return adults;
}
